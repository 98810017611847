<template>
  <div id="calculations-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <button class="btn btn-secondary mb-5" disabled="disabled">Analytics Konten verbinden (bald)</button>

    <!--<ComingSoonBanner></ComingSoonBanner>-->

    <!--<p>Todo:</p>
    <ul>
      <li>Build Calculations</li>
      <li>Build Charts</li>
      <li>Connect to <router-link to="/experiments">Experiments</router-link></li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/channels">Traffic Channels</router-link></li>
    </ul>-->

    <IntroBanner v-if="!view" @click="view = 'funnel'"></IntroBanner>

    <div v-else>
      <ul class="nav nav-tabs mb-3">
        <li class="nav-item">
          <a :class="view === 'funnel' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'funnel'">Conversion-Funnel</a>
        </li>
        <li class="nav-item">
          <a :class="view === 'potential' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'potential'">Potential-Analyse</a>
        </li>
        <li class="nav-item">
          <a :class="view === 'roi' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'roi'">ROI-Berechnung</a>
        </li>
      </ul>

      <div v-if="view === 'funnel'" class="card mb-4">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-lg">
              <h3>Dein Conversion-Funnel</h3>
            </div>
            <div v-if="selectedFunnelId !== 'custom'" class="col-sm-5 col-lg-3">
              <select v-model="selectedMetric" class="form-control">
                <option value="" disabled>Funnel-Metrik auswählen</option>
                <option v-for="(metric, index) in project.settings.nodeMetrics" :value="metric.id" :key="index">{{ metric.title }}</option>
              </select>
            </div>
            <div class="col-sm-5 col-lg-3">
              <select v-model="selectedFunnelId" class="form-control">
                <option value="" disabled>Funnel auswählen</option>
                <option value="custom">Benutzerdefinierter Funnel</option>
                <option v-for="(journey, index) in project.journeys" :value="journey.id" :key="index">
                  {{ journey.title }} (Werte werden nicht gespeichert)
                </option>
              </select>
            </div>
            <div class="col-sm-2 col-lg-1">
              <select v-model="currency" class="form-control">
                <option value="€">€</option>
                <option value="$">$</option>
                <option value="¥">¥</option>
              </select>
            </div>
          </div>

          <CalculationFunnel :funnel-steps="selectedFunnel"
                             :scale="scale"
                             :currency="currency"
                             :editable="selectedFunnelId === 'custom'"
          ></CalculationFunnel>
        </div>
      </div>

      <div v-if="view === 'potential'" class="card mb-3">
        <div class="card-body">
          <h3>Potential-Rechner</h3>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label>Bestellungen</label>
                <div class="input-group">
                  <input v-model.number="orders" class="form-control" type="number">
                  <div class="input-group-append">
                    <span class="input-group-text">Cv</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Durchschnittswert pro Bestellung</label>
                <div class="input-group">
                  <input v-model.number="valuePerConversion" class="form-control" type="number">
                  <div class="input-group-append">
                    <span class="input-group-text">{{ currency }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Umsatz-Gewinn Verhältnis (Wie viel Gewinn bleibt vom Umsatz übrig?)</label>
                <div class="input-group">
                  <input v-model.number="earningsRatio" class="form-control" type="number">
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>

              <hr>

              <div class="form-group">
                <label>Conversionrate-Uplift</label>
                <div class="input-group">
                  <input v-model.number="conversionUplift" class="form-control" type="number">
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <table class="table text-centered">
                <thead>
                <tr>
                  <th colspan="5">Gewinn bei X % Uplift</th>
                </tr>
                <tr>
                  <th>{{ scale }}</th>
                  <th>0%</th>
                  <th>Total Uplift: {{ totalEarningsUplift }}%</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="y in Array.from({length: 10}, (x,i) => i + 1)" :key="y">
                  <th>{{ y }}</th>
                  <td>{{ cur(y * orders * valuePerConversion * (earningsRatio / 100)) }}</td>
                  <td>
                    {{
                      cur(y * orders * (1 + conversionUplift / 100) * valuePerConversion * (earningsRatio / 100))
                    }}
                  </td><!--
                  <td>{{ cur(y * earnings * (1 + totalEarningsUplift / 200)) }}</td>
                  <td>{{ cur(y * earnings * (1 + totalEarningsUplift / 100)) }}</td>
                  <td>{{ cur(y * earnings * (1 + totalEarningsUplift / 50)) }}</td>-->
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div v-if="view === 'roi'" class="card">
      <div class="card-body">
        <h2>Return on Investment</h2>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label>Gewinn pro {{ scale }}</label>
              <div class="input-group">
                <input v-model.number="earnings" class="form-control" type="number">
                <div class="input-group-append">
                  <span class="input-group-text">{{ currency }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Projektkosten insgesamt</label>
              <div class="input-group">
                <input v-model.number="projectCost" class="form-control" type="number">
                <div class="input-group-append">
                  <span class="input-group-text">{{ currency }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Einmaliger Gesamt-Uplift</label>
              <div class="input-group">
                <input v-model.number="conversionUplift" class="form-control" type="number">
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <p>Break-Even:</p>
            <p class="h2">{{ breakEven }} {{ scale }}</p>
            <hr>
            <p>Return on Investment:</p>
            <p class="h2">{{ ROI }} %</p>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CalculationFunnel from "@/components/CalculationFunnel";
import IntroBanner from "@/components/IntroBanner";

export default {
  name: 'Calculations',
  components: {
    IntroBanner,
    CalculationFunnel,
  },
  data() {
    return {
      id: this.generateId(),
      view: "",
      selectedFunnelId: "custom",
      selectedMetric: "",
      currency: "€",
      scale: "Jahre",
      funnelSteps: [
        {
          title: "Views",
          visits: 30000,
          visitsUnique: 30000,
        },
        {
          title: "Visits",
          visits: 100000,
          visitsUnique: 50000,
        },
        {
          title: "Warenkörbe",
          visits: 5000,
          visitsUnique: 5000,
        },
        {
          title: "Abschlüsse",
          visits: 1000,
          visitsUnique: 1000,
        },
        {
          title: "Umsatz",
          visits: 10000,
          visitsUnique: 10000,
          type: "currency"
        },
        {
          title: "Gewinn",
          visits: 5000,
          visitsUnique: 5000,
          type: "currency"
        },
      ],
      adClicks: 2000,
      visits: 100000,
      carts: 5000,
      orders: 1000,
      revenue: 10000,
      earnings: 5000,
      projectCost: 5000,
      conversionRate: 1,
      valuePerConversion: 10,
      earningsRatio: 50,
      conversionUplift: 10,
      newStep: {
        title: "",
        visits: 0,
        visitors: 0,
        conversionRate: 0,
      }
    }
  },
  watch: {
    visits(newVal) {
      this.conversionRate = Math.round(this.orders / newVal * 100);
    },
    revenue(newVal) {
      this.valuePerConversion = Math.round(newVal / this.orders);
      this.earningsRatio = Math.round(this.earnings / newVal * 100);
    },
    earnings(newVal) {
      this.earningsRatio = Math.round(newVal / this.revenue * 100);
    },
    selectedFunnelId(newVal) {
      if (newVal !== 'custom') {
        this.selectedMetric = this.project.settings.nodeMetrics[0].id;
      }
    },
    funnelSteps: {
      deep: true,
      handler: function (newVal) {
        this.$store.dispatch('project/updateProjectByProp', {prop: 'calculations', data: [ {funnelSteps: newVal }]});
      }
    }
  },
  computed: {
    calculations() {
      return this.project.calculations;
    },
    selectedFunnel() {
      if (this.selectedFunnelId === "custom") {
        return this.funnelSteps;
      } else {
        let steps = JSON.parse(JSON.stringify(this.project.journeys))
            .find(journey => journey.id === this.selectedFunnelId).steps;
        steps.map(step => {
          step.title = step.nodeUrl;
          step.node = this.findNode(step.nodeUrl);
          step.visits = step.node.metrics[this.selectedMetric] || 1;
          return step;
        });
        return steps;
      }
    },
    totalEarningsUplift() {
      let newVal = this.visits * this.conversionRate / 100 * (1 + this.conversionUplift / 100) * this.valuePerConversion * (this.earningsRatio / 100);
      return ((newVal / this.earnings - 1) * 100).toFixed();
    },
    funnelValues() {
      return [this.visits, this.carts, this.orders, this.revenue, this.earnings]
    },
    ROI() {
      return Math.round((this.earnings * (1 + this.conversionUplift / 100) - this.projectCost) / this.projectCost * 100);
    },
    breakEven() {
      let e = this.earnings * (1 + this.conversionUplift / 100);
      let c = this.projectCost;
      return e === c ? 1 : Math.ceil(c / e);
    }
  },
  methods: {
  },
  beforeMount() {
    if (this.calculations[0]) {
      this.funnelSteps = this.calculations[0].funnelSteps;
      this.view = 'funnel';
    }
  }
}
</script>