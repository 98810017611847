<template>
  <div id="calculations-funnel">
    <div class="row">
      <div class="col-sm-5 col-lg-auto">
        <table class="table mt-2">
          <thead>
          <tr>
            <th class="border-top-0">Metrik</th>
            <th class="border-top-0">Verhältnis</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(funnelStep, index) in funnelSteps" class="funnel-step" :key="index">
            <td class="p-1">
              <div class="form-group mb-2">
                <div v-if="editable" @click="addStep(index)" class="add-step d-inline mr-1"><i class="fad fa-plus-circle text-success"></i></div>
                <div v-if="editable" @click="removeStep(index)" class="remove-step d-inline mr-1"><i class="fad fa-minus-circle text-danger"></i></div>
                <input v-if="editable" v-model="funnelStep.title" placeholder="Metrik-Bezeichnung" class="w-75 naked mb-0"/>
                <span v-else style="text-overflow:ellipsis;white-space: nowrap" >
                  {{ funnelStep.name }}
                  <Tooltip>
                      <code>{{ funnelStep.nodeUrl }}</code>
                  </Tooltip>
                </span>
                <div class="input-group">
                  <input @change="setMetric(funnelStep, $event)" :value="funnelStep.visits" class="form-control" type="number">
                  <div v-if="funnelStep.type === 'currency'" class="input-group-append">
                    <span class="input-group-text">{{ currency }}</span>
                  </div>
                  <div v-else class="input-group-append">
                    <span class="input-group-text">Cv</span>
                  </div>
                </div>
              </div>
            </td>
            <td class="p-1 text-center">
              <div class="form-group mb-2">
                <label class="mb-0">Visits : X</label>
                <div class="form-control disabled">
                  {{ (funnelSteps[0].visits / funnelStep.visits).toFixed() }} : 1
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
      <div class="col-sm-7 col-lg pt-4">
        <Funnel :values="funnelSteps.map(s => s.visits)"
                :show-values="true"
                class="mt-4"
        ></Funnel>
      </div>
    </div>

    <p class="text-muted">
      Cv = Conversions
    </p>
  </div>
</template>

<script>
// @ is an alias to /src
import Funnel from "@/components/Funnel";
import Tooltip from "@/components/Tooltip";

export default {
  name: 'CalculationFunnel',
  components: {
    Tooltip,
    Funnel,
  },
  props: {
    funnelSteps: Array,
    currency: String,
    scale: String,
    editable: Boolean
  },
  data() {
    return {
      newStep: {
        title: "",
        visits: 0,
        visitors: 0,
        conversionRate: 0,
      }
    }
  },
  computed: {},
  methods: {
    setMetric(step, $event) {
      let index = this.funnelSteps.findIndex(s => s.title === step.title);
      let temp = JSON.parse(JSON.stringify(this.funnelSteps[index]));
      temp.visits = $event.target.value;
      this.funnelSteps.splice(index, 1, temp);
      this.$forceUpdate();
    },
    addStep(index) {
      this.funnelSteps.splice(index, 0, {
        title: "",
        visits: 1,
        visitsUnique: 1
      });
    },
    removeStep(index) {
      this.funnelSteps.splice(index, 1);
    }
  }
}
</script>

<style lang="scss">
.funnel-step {
  position: relative;
}
</style>